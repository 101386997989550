import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import ProductHero from "../components/Hero/ProductHero"
import RichText from "../components/RichText"
import Cards from "../components/Cards/Cards"
import ShopCard from "../components/CardTypes/ShopCard"
import Tabs from "../components/Tabs"
import BenefitsTab from "../components/FlavourPage/BenefitsTab"
import NutritionTab from "../components/FlavourPage/NutritionTab"
import IngredientsTab from "../components/FlavourPage/IngredientsTab"

import { getPlainText, getPrettyURL, isEmptyRichText } from "../utils"
import { ProductFlavourModel } from "../models/pages"
import { HeroSlide, SeoDataModel } from "../models/common"
import FAQTab from "../components/FlavourPage/FAQTab"
import useDictionary from "../hooks/useDictionary"
import { getSKUImage } from "../utils/utils_images"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

export const ProductFlavourPage: React.FC<ProductFlavourModel> = props => {
  // page data
  const _page =  get(props, "data.page") as any as ProductFlavourModel;
  _page.pageContext = props.pageContext
  const [page, setPage] = useState(_page);

    useEffect(() => {
      contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext))
  }, [])

  const product = page.product ? page.product[0] : undefined
  // console.log("ProductFlavourPage: ", page)
  page.pageContext = props.pageContext

  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    metaTitle: page.seo?.title || heroHeading,
    metaDescription:
      page.seo?.description || getPlainText(page.listing?.excerpt),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }


    let heroSlide: HeroSlide[] = []

  if (page.skuvantage?.front_image && !page.skuvantage?.no_skuvantage) {
    heroSlide.push({
      skuImage: getSKUImage(page.skuvantage?.front_image),
    })
  } else if (page.hero.hero_image) {
    heroSlide.push({
      image: page.hero.hero_image,
    })
  }

  if (page.skuvantage?.back_image && !page.skuvantage?.no_skuvantage) {
    heroSlide.push({
      skuImage: getSKUImage(page.skuvantage?.back_image),
    })
  } else if (page.additional_images) {
    heroSlide = [...heroSlide, ...page.additional_images]
  }

  // combine hero and additional images but remove duplicates (based on ID)
  const slideImages = [...heroSlide, ...(page.additional_images || [])].filter(
    (value, index, self) =>
      value.image
        ? index === self.findIndex(x => x.image?.id === value.image?.id)
        : true
  )

  // info tabs
  let tabs: any[] = []
  if (page.benefits || page.health_rating)
    tabs.push({
      heading: useDictionary("Benefits", props.pageContext.locale),
      content: <BenefitsTab {...page} />,
    })
  if (page.nutrition)
    tabs.push({
      heading: useDictionary("Nutrition", props.pageContext.locale),
      content: <NutritionTab {...page} pageContext={props.pageContext} />,
    })
  if (!isEmptyRichText(page.ingredients))
    tabs.push({
      heading: useDictionary("Ingredients", props.pageContext.locale),
      content: <IngredientsTab {...page} />,
    })
  if (!isEmptyRichText(page.faqs))
    tabs.push({
      heading: useDictionary("FAQ", props.pageContext.locale),
      content: <FAQTab {...page} />,
    })

  return (
    <div className="productFlavourPage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      {!page.hero.hideBreadcrumbs && (
        <Breadcrumbs
          breadcrumbs={(product?.url_structure || []).concat(product || [])}
          currentPage={navLabel || heroHeading}
          pageContext={props.pageContext}
        />
      )}

      {/* Hero Images */}
      <ProductHero slides={slideImages} />

      {/* Content */}
      <section>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <h2>{heroHeading}</h2>
                <RichText
                  content={page.content}
                  cssClass="content"
                  pageContext={props.pageContext}
                />
                {!isEmptyRichText(page.content) &&
                  !isEmptyRichText(page.cooking_instructions) && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                {!isEmptyRichText(page.cooking_instructions) && (
                  <>
                    <h2>{useDictionary("Cooking Instructions", props.pageContext.locale)}</h2>
                    <RichText
                      content={page.cooking_instructions}
                      cssClass="content"
                      pageContext={props.pageContext}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="grid-container">
          <hr />
        </div>
      </section>

      {/* Available At */}
      {page.available_at && page.available_at.length > 0 && (
        <section>
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <div className="page__body">
                  <h2>{useDictionary("Buy now", props.pageContext.locale)}</h2>
                  <br />
                  <div className="grid-x grid-margin-x align-center">
                    {page.available_at?.map((shop, index) => (
                      <div
                        key={`shop_${index}`}
                        className="cell small-6 large-4"
                      >
                        <ShopCard {...shop} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Tabs */}
      <section>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <Tabs tabs={tabs} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Flavours */}
      {product?.flavours && (
        <Cards
          heading={useDictionary("Choose a flavour", props.pageContext.locale)}
          cards={product.flavours}
          pageContext={props.pageContext}
        />
      )}
    </div>
  )
}

ProductFlavourPage.defaultProps = defaultProps

export default ProductFlavourPage

/****
	Data
****/
export const query = graphql`
  query ProductFlavourPageByID($pageId: String!) {
    page: contentstackProductFlavour(id: { eq: $pageId }) {
      __typename
      uid
      ...ProductFlavourFields
      hero {
        hide_breadcrumbs
      }
      updated_at
      additional_images {
        image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
        image_focus
        video_url
        video_date
      }

      skuvantage {
        no_skuvantage
        front_image
        back_image
      }
      product {
        ...ProductFields
        flavours {
          ...ProductFlavourFields
        }
      }
      content
      cooking_instructions
      benefits
      faqs
      health_rating {
        ...CmsImageFields
        # gatsbyImageData(width: 780, quality: 95)
        gatsbyImageData(quality: 95)
      }
      hsr_text
      serving_size
      quantity_per_serve
      servings_per_pack
      nutrition {
        per_quantity
        energy_kj_per_serve
        energy_kj_per_100g
        energy_cal_per_serve
        energy_cal_per_100g
        protein_per_serve
        protein_per_100g
        gluten_per_serve
        gluten_per_100g
        fat_per_serve
        fat_per_100g
        saturated_fat_per_serve
        saturated_fat_per_100g
        trans_fat_per_serve
        trans_fat_per_100g
        polyunsaturated_fat_per_serve
        polyunsaturated_fat_per_100g
        monounsaturated_fat_per_serve
        monounsaturated_fat_per_100g
        carbohydrate_per_serve
        carbohydrate_per_100g
        sugars_per_serve
        sugars_per_100g
        dietary_fibre_per_serve
        dietary_fibre_per_100g
        prebiotic_fibre_per_serve
        prebiotic_fibre_per_100g
        beta_glucan_per_serve
        beta_glucan_per_100g
        sodium_per_serve
        sodium_per_100g
        potassium_per_serve
        potassium_per_100g
        vitamin_a_per_serve
        vitamin_a_per_100g
        thiamin_per_serve
        thiamin_per_100g
        riboflavin_per_serve
        riboflavin_per_100g
        niacin_per_serve
        niacin_per_100g
        vitamin_b6_per_serve
        vitamin_b6_per_100g
        vitamin_b12_per_serve
        vitamin_b12_per_100g
        vitamin_c_per_serve
        vitamin_c_per_100g
        vitamin_d_per_serve
        vitamin_d_per_100g
        vitamin_e_per_serve
        vitamin_e_per_100g
        folate_per_serve
        folate_per_100g
        iron_per_serve
        iron_per_100g
        magnesium_per_serve
        magnesium_per_100g
        calcium_per_serve
        calcium_per_100g
        phosphorus_per_serve
        phosphorus_per_100g
        fibre_per_serve
        fibre_per_100g
        isoflavones_per_serve
        isoflavones_per_100g
        glycaemic_index_gi_per_serve
        glycaemic_index_gi_per_100g
        polyphenols_total_per_serve
        polyphenols_total_per_100g
        plant_sterols_per_serve
        plant_sterols_per_100g
        zinc_per_serve
        zinc_per_100g
        selenium_mcg_per_serve
        selenium_mcg_per_100g
        lactose_g_per_serve
        lactose_g_per_100g
        galactose_g_per_serve
        galactose_g_per_100g
      }
      nutrition_footer
      ingredients
      available_at {
        store_logo {
          ...CmsImageFields
          gatsbyImageData(height: 130, quality: 95)
        }
        product_link
      }
      unrelated
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }
  }

  fragment ProductFlavourFields on Contentstack_product_flavour {
    id
    url
    updated_at
    hero {
      heading
      image_focus
      hero_image {
        ...HeroImageFields
      }
      listing_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(width: 590, quality: 95)
      }
      mini_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 100, quality: 95)
      }
    }

    skuvantage {
      no_skuvantage
      front_image
      back_image
    }
    product {
      id
      url
      url_structure {
        ...UrlStructureFields
      }
      hero {
        heading
      }
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
